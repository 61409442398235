<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useBBSettingsStore } from '../stores/bb-settings'
import { useModal, useToast } from 'vuestic-ui/web-components'
import { ref } from 'vue'
import { SiteDisabledReasonType } from '../pages/bb-settings/types'

const { confirm } = useModal()
const { init } = useToast()
const { settings } = storeToRefs(useBBSettingsStore())

const isLoading = ref(false)
const isShow = ref(false)

useBBSettingsStore().getSettings()

const changedReason = ref<SiteDisabledReasonType>()

function handleOnOffTrade() {
  if (settings.value.isSiteEnabled) {
    isShow.value = true
  } else {
    confirmOnOfSite()
  }
}

async function confirmOnOfSite() {
  confirm({
    title: `
        Вы уверены что хотите
        ${settings.value.isSiteEnabled ? 'остановить' : 'возобновить'}
        обмен на сайте?
      `,
    size: 'small',
    maxWidth: '380px',
  }).then(async (ok) => {
    if (!ok) return

    await useBBSettingsStore()
      .updateSettings({
        isSiteEnabled: !settings.value.isSiteEnabled,
        changedReason: changedReason.value,
      })
      .then(() => {
        init({ message: 'Успешно', color: 'success' })
        reset()
      })
      .catch(() => {
        init({ message: 'Что-то пошло не так', color: 'danger' })
      })
  })
}

function reset() {
  changedReason.value = undefined
  isShow.value = false
  isLoading.value = false
}
</script>

<template>
  <div class="on-off-site">
    <VaButton :color="settings.isSiteEnabled ? 'danger' : 'success'" :disabled="isShow" @click="handleOnOffTrade">
      {{ settings.isSiteEnabled ? 'Остановить обмен' : 'Возобновить обмен' }}
    </VaButton>

    <VaCollapse v-model="isShow" class="w-full" :disabled="isLoading">
      <template #header>{{ ' ' }}</template>

      <template #body>
        <div class="on-off-site__content">
          <VaSelect
            v-model="changedReason"
            :options="settings.siteDisabledReasons ?? []"
            track-by="_id"
            text-by="name"
            name="reason"
            placeholder="Выберите причину"
          />

          <div class="buttons">
            <VaButton color="success" :disabled="!changedReason || isLoading" @click="confirmOnOfSite">
              Выключить
            </VaButton>

            <VaButton color="danger" :disabled="isLoading" @click.prevent="reset"> Отмена </VaButton>
          </div>
        </div>
      </template>
    </VaCollapse>
  </div>
</template>

<style lang="scss" scoped>
.on-off-site {
  @apply pt-6 px-2 pb-2;

  &__content {
    @apply flex flex-col py-2 gap-y-2;
  }
}

.buttons {
  @apply flex items-center justify-between;
}
</style>
