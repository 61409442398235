export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'valute-pairs',
      displayName: 'menu.valute-pairs',
      meta: {
        icon: 'mso-sync_alt',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'mso-group',
      },
    },
    {
      name: 'transactions',
      displayName: 'menu.transactions',
      meta: {
        icon: 'mso-contract',
      },
    },
    {
      name: 'outputs',
      displayName: 'menu.outputs',
      meta: {
        icon: 'mso-payments',
      },
    },
    {
      name: 'wallets',
      displayName: 'menu.wallets',
      meta: {
        icon: 'mso-account_balance_wallet',
      },
    },
    {
      name: 'valutes',
      displayName: 'menu.valutes',
      meta: {
        icon: 'mso-currency_bitcoin',
      },
    },
  ] as INavigationRoute[],
}
