import { useAuthStore } from '../stores/bb-auth'
import { DefaultRequestParamsType } from '../types/api'

type MethodType = 'POST' | 'PUT' | 'DELETE'

let authToken = JSON.parse(localStorage.getItem('Auth') ?? '{}').token ?? ''
let csrfToken = ''
const csrfFullUrl = import.meta.env.VITE_API_URL + '/csrf-token'

const defaultGetRequestOptions: RequestInit = {
  method: 'GET',
  credentials: 'include',
  mode: 'cors',
}

async function getCsrf() {
  const res = await fetch(csrfFullUrl, defaultGetRequestOptions)

  if (res.ok) {
    const { csrfToken: token } = await res.json()
    csrfToken = token
  } else {
    return Promise.reject('CSRF request error!')
  }
}

function getParamsString(params: DefaultRequestParamsType, isList = true) {
  if (params && Object.keys(params).length) {
    if (isList) {
      if (params.perPage || params.perPage === 0) {
        params.limit = params.perPage
        delete params.perPage
      } else {
        params.limit = params.limit ?? 32
      }

      if (params.page) {
        params.skip = params.page != 0 ? params.limit * (params.page - 1) : 0
        delete params.page
      }
    }

    const paramsArr: string[] = []
    Object.entries(params).forEach(([key, value]) => {
      if (value) paramsArr.push(encodeURI(`${key}=${typeof value === 'object' ? JSON.stringify(value) : value}`))
    })

    return '?' + paramsArr.join('&')
  }

  return ''
}

export default {
  get: async function <T>(url: string, params?: object): Promise<T> {
    if (!authToken) return Promise.reject('Unauthorized')

    let fullUrl = import.meta.env.VITE_API_URL + url

    if (params) fullUrl += getParamsString(params)

    return await fetch(fullUrl, {
      ...defaultGetRequestOptions,
      headers: { Token: authToken },
    })
      .then(async (res) => {
        const data = await res.json()
        return data.status ? data : Promise.reject(res)
      })
      .catch(async (res) => {
        if (res.status === 401) {
          localStorage.removeItem('Auth')
          location.reload()
        }

        return Promise.reject(res.message)
      })
  },
  auth: async function <T>(url: string, body: object): Promise<T> {
    const fullUrl = import.meta.env.VITE_API_URL + url

    if (!csrfToken) await getCsrf()

    return await fetch(fullUrl, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'x-csrf-token': csrfToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        const data = await res.json()
        return data.status ? data : Promise.reject(data.message)
      })
      .then((data) => {
        localStorage.setItem('Auth', JSON.stringify(data.result))
        authToken = data.result.token
        useAuthStore().type = data.result.type

        return data
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  useFormData(data: Record<string, any>, formData = new FormData(), parentKey?: string): FormData {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key]
        const formKey = parentKey ? `${parentKey}[${key}]` : key

        if (value instanceof Date) {
          formData.append(formKey, value.toISOString())
        } else if (value instanceof File) {
          formData.append(formKey, value)
        } else if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
          this.useFormData(value, formData, formKey)
        } else if (value !== undefined && value !== null) {
          formData.append(formKey, String(value))
        }
      }
    }
    return formData
  },
  protectedRequestWithFormData: async function <T>(url: string, body: object): Promise<T> {
    if (!csrfToken) await getCsrf()
    if (!authToken) return Promise.reject('Unauthorized')
    if (!body) return Promise.reject('No body')

    return await fetch(import.meta.env.VITE_API_URL + url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'x-csrf-token': csrfToken,
        token: authToken,
        // 'Content-Type': 'multipart/form-data',
      },
      body: this.useFormData(body),
    })
      .then(async (res) => {
        const data = await res.json()
        return data.status ? data : Promise.reject(data.message)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  protectedRequest: async function <T>(method: MethodType, url: string, body?: object): Promise<T> {
    if (!csrfToken) await getCsrf()
    if (!authToken) return Promise.reject('Unauthorized')

    return await fetch(import.meta.env.VITE_API_URL + url, {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'x-csrf-token': csrfToken,
        token: authToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        const data = await res.json()
        return data.status ? data : Promise.reject(data.message ?? data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
}
