import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import api from '../utils/api'
import { IResponse, PaginatorType } from '../types/api'
import { ValutePairsType, ValutePairsTableItemType, ValuteType } from '../pages/bb-valute-pairs/types'
import { IPairsGetParams } from '../types/api/valute'

export const useValutePairsStore = defineStore('valute-pairs', () => {
  const valutes = ref<ValuteType[]>([])
  const pairs = ref<ValutePairsTableItemType[]>([])

  const paginator = ref<PaginatorType>({
    limit: 32,
    skip: 0,
    total: 0,
  })

  const pairsGetParams = reactive<IPairsGetParams>({
    page: 1,
    perPage: 32,
    sortBy: 'fromValute',
    sortDir: 'asc',
    filters: {
      fromValuteIds: [],
      toValuteIds: [],
      commission: { sign: '$gte', value: -100 },
      isActive: null,
      autoCommission: {
        minCommissionPercent: { sign: '$gte', value: -100 },
        stepOfInPercent: { sign: '$gte', value: -100 },
        position: { sign: '$gte', value: -100 },
        isActive: null,
      },
    },
    // isCreateNew: true,
  })

  const lastPage = computed(() => Math.ceil(paginator.value.total / paginator.value.limit))

  function formatValutePair(pair: ValutePairsType): ValutePairsTableItemType {
    const course = pair.fromValute.course / pair.toValute.course

    const inCount =
      pair.fromValute.course > pair.toValute.course
        ? 1
        : (pair.toValute.course / pair.fromValute.course) * (1 + pair.commissionPercent / 100)

    const outCount =
      pair.toValute.course > pair.fromValute.course
        ? 1
        : (pair.fromValute.course / pair.toValute.course) * (1 - pair.commissionPercent / 100)

    return {
      ...pair,
      course: course * (1 + pair.commissionPercent / 100),
      in: inCount,
      out: outCount,
    }
  }

  // ------------------------------------------- VALUTES -------------------------------------------
  async function getValutes() {
    return await api
      .get<IResponse<ValuteType[]>>('/valutes')
      .then((res) => {
        valutes.value = res.result!
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  // ---------------------------------------- VALUTE PAIRS -----------------------------------------
  async function getPairs() {
    return await api
      .get<IResponse<ValutePairsType[]>>('/valute-pairs', pairsGetParams)
      .then((res) => {
        pairs.value = res.result!.map(formatValutePair)

        if (res.paginator) paginator.value = res.paginator
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  async function editPairs(ids: string[], { commissionPercent, isActive, autoCommission }: ValutePairsTableItemType) {
    return await api
      .protectedRequest<IResponse<ValutePairsType[]>>('POST', '/valute-pairs', {
        ids,
        changes: { commissionPercent, isActive, autoCommission },
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  // --------------------------------------- AUTO COMMISSION ---------------------------------------
  async function getTestAutoCommissions() {
    return await api.get('/auto-commission/bestchange').catch((err) => {
      return Promise.reject(err)
    })
  }

  async function getTestAutoCommissionsOld() {
    return await api.get('/auto-commission/test').catch((err) => {
      return Promise.reject(err)
    })
  }

  async function addAutoCommission(valutePairId: string) {
    return await api
      .protectedRequest('POST', '/valute-pairs/auto-commission', {
        valutePair_id: valutePairId,
      })
      .then(() => {
        getPairs()
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  function updateCommissions(data: { _id: string; value: string }[]) {
    data.forEach(({ _id, value }) => {
      const pairIndex = pairs.value.findIndex((pair) => pair._id === _id)

      if (pairIndex > -1) {
        pairs.value[pairIndex].commissionPercent = parseFloat(value)
      }
    })
  }

  // --------------------------------------------- ALL ---------------------------------------------
  async function getAll() {
    Promise.all([getValutes(), getPairs()]).catch((err) => err)
  }

  return {
    getValutes,
    getPairs,
    editPairs,
    pairsGetParams,
    valutes,
    pairs,
    lastPage,
    addAutoCommission,
    getAll,
    getTestAutoCommissions,
    getTestAutoCommissionsOld,
    updateCommissions,
  }
})
