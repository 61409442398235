import { defineStore } from 'pinia'
import { ref } from 'vue'
import api from '../utils/api'
import { IResponse } from '../types/api'
import { ChangeTransStatusPayloadType } from '../types/api/transactions'
import { TransactionType } from '../pages/bb-transactions/types'

export const useTransactionsStore = defineStore('transactions', () => {
  const isOpenVerifyModal = ref(false)
  const transaction = ref<TransactionType>()

  const params = ref({
    skip: 0,
    limit: 8,
    total: 0,
  })
  const imageUrl = ref('http://localhost:3000/uploads/verificationImages/')
  const transactions = ref<TransactionType[]>([])

  async function getTransactions(id?: string) {
    return await api.get<IResponse<TransactionType[]>>('/transactions/v2', { ...params.value, id }).then((res) => {
      // @ts-expect-error костыль...
      imageUrl.value = res.imageUrl
      params.value = res.paginator!

      if (res.paginator?.skip !== 0) {
        transactions.value = transactions.value.concat(res.result!)
      } else {
        transactions.value = res.result!
      }
    })
  }

  async function deleteTransaction(ID: string) {
    return await api.protectedRequest<IResponse<any>>('DELETE', `/transactions/${ID}`, {}).then(() => {
      getTransactions()
    })
  }

  async function changeTransactionStatus(payload: ChangeTransStatusPayloadType) {
    return await api.protectedRequest('POST', `/transactions/${payload._id}`, payload).then(() => {
      getTransactions()
    })
  }

  async function saveVerifiedCard(cardNumber: string, payload: ChangeTransStatusPayloadType) {
    return await api.protectedRequest('POST', '/bankCards', { cardNumber }).then(() => {
      changeTransactionStatus(payload)
    })
  }

  return {
    getTransactions,
    deleteTransaction,
    changeTransactionStatus,
    saveVerifiedCard,
    transactions,
    transaction,
    imageUrl,
    params,
    isOpenVerifyModal,
  }
})
