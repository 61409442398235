import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

// import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useAuthGuard } from './middleware/auth'

// const exampleRoutes = [
//   {
//     name: 'dashboard',
//     path: 'dashboard',
//     component: () => import('../pages/admin/dashboard/Dashboard.vue'),
//     beforeEnter: useAuthGuard,
//   },
//   {
//     name: 'preferences',
//     path: 'preferences',
//     component: () => import('../pages/preferences/Preferences.vue'),
//     beforeEnter: useAuthGuard,
//   },
//   {
//     name: 'projects',
//     path: 'projects',
//     component: () => import('../pages/projects/ProjectsPage.vue'),
//     beforeEnter: useAuthGuard,
//   },
//   {
//     name: 'payments',
//     path: '/payments',
//     component: RouteViewComponent,
//     children: [
//       {
//         name: 'payment-methods',
//         path: 'payment-methods',
//         component: () => import('../pages/payments/PaymentsPage.vue'),
//         beforeEnter: useAuthGuard,
//       },
//       {
//         name: 'billing',
//         path: 'billing',
//         component: () => import('../pages/billing/BillingPage.vue'),
//         beforeEnter: useAuthGuard,
//       },
//       {
//         name: 'pricing-plans',
//         path: 'pricing-plans',
//         component: () => import('../pages/pricing-plans/PricingPlans.vue'),
//         beforeEnter: useAuthGuard,
//       },
//     ],
//   },
//   {
//     name: 'faq',
//     path: '/faq',
//     component: () => import('../pages/faq/FaqPage.vue'),
//     beforeEnter: useAuthGuard,
//   },
// ]

const routes: Array<RouteRecordRaw> = [
  {
    name: 'logout',
    path: '/logout',
    component: () => import('../pages/auth/BBLogout.vue'),
  },
  {
    name: 'auth',
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/BBLogin.vue'),
      },
      // {
      //   path: '*',
      //   redirect: { name: 'login' },
      // },
    ],
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'valute-pairs' },
    children: [
      {
        name: 'valute-pairs',
        path: 'valute-pairs',
        component: () => import('../pages/bb-valute-pairs/BBValutePairsPages.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/bb-users/BBUsersPages.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'transactions',
        path: 'transactions',
        component: () => import('../pages/bb-transactions/BBTransactionsPage.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'outputs',
        path: 'outputs',
        component: () => import('../pages/bb-outputs/BBOutputsPage.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'wallets',
        path: 'wallets',
        component: () => import('../pages/bb-wallets/BBWalletsPage.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'valutes',
        path: 'valutes',
        component: () => import('../pages/bb-valutes/BBValutesPage.vue'),
        beforeEnter: useAuthGuard,
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
        beforeEnter: useAuthGuard,
      },
      // ...(process.env.NODE_ENV === 'prodaction' || process.env.NODE_ENV === 'staging' ? [] : exampleRoutes),
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'valute-pairs' },
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
