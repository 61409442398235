import { defineStore } from 'pinia'
import api from '../utils/api'
import { IResponse } from '../types/api'
import { ref } from 'vue'
import { OutputsStatusType, OutputType } from '../pages/bb-outputs/types'

export const useOutputsStore = defineStore('outputs', () => {
  const params = ref({
    skip: 0,
    limit: 8,
    total: 0,
  })

  const outputs = ref<OutputType[]>([])

  async function getOutputs(_id?: string) {
    return await api.get<IResponse<OutputType[]>>('/outputs', { ...params.value, _id }).then((res) => {
      if (res.paginator) params.value = res.paginator

      if (res.paginator && res.paginator.skip !== 0) {
        outputs.value = outputs.value.concat(res.result!)
      } else {
        outputs.value = res.result!
      }
    })
  }

  async function changeOutputStatus(payload: { _id: string; status: OutputsStatusType }) {
    return await api.protectedRequest('POST', `/outputs/${payload._id}`, payload).then(() => {
      getOutputs()
    })
  }

  return {
    params,
    outputs,
    getOutputs,
    changeOutputStatus,
  }
})
