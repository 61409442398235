import { defineStore } from 'pinia'
import api from '../utils/api'
import { IAuthRequestBody, IAuthResult, ICheckTokenRequestBody } from '../types/api/auth'
import { IResponse } from '../types/api'
import { ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const type = ref(3)

  async function auth(body: IAuthRequestBody) {
    return await api
      .auth<IResponse<IAuthResult>>('/auth/login', body)
      .then((data) => data)
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  }

  async function checkToken(body: ICheckTokenRequestBody) {
    return await api
      .protectedRequest('POST', '/check-token', body)
      .then((data) => data)
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  }

  return {
    type,
    auth,
    checkToken,
  }
})
