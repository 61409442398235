import { defineStore } from 'pinia'
import api from '../utils/api'
import { IResponse } from '../types/api'
import { ref } from 'vue'
import { ISettings } from '../pages/bb-settings/types'

export const useBBSettingsStore = defineStore('settings', () => {
  const settings = ref<ISettings>({
    isSiteEnabled: false,
    siteDisabledReasons: [],
  })

  async function getSettings() {
    return await api
      .get<IResponse<any>>('/info')
      .then((data) => {
        settings.value = data.result
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  }

  async function updateSettings(body: any) {
    return await api
      .protectedRequest('POST', '/info', body)
      .then(getSettings)
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  }

  return {
    settings,
    getSettings,
    updateSettings,
  }
})
